import { css } from '@emotion/css';

export const FlexCss = {
  alignCenter: css({
    alignItems: 'center',
  }),
  justifyContentCenter: css({
    justifyContent: 'center',
  }),
  spaceBetween: css({
  justifyContent: 'space-between',
  }),
}